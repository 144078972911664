<template>
  <CreateAssetTypeStyled>
    <header>Create Asset Type</header>
    <InputStyled :required="!$v.name.required">
      <input v-model.trim="$v.name.$model" :placeholder="$tc('assetType')" />
    </InputStyled>
    <p class="errors" v-if="!$v.name.isUnique">{{ $tc('messages.notUnique') }}</p>
    <p class="errors" v-if="$v.name.$dirty && !$v.name.required">{{ $tc('messages.required') }}</p>
    <p class="error" v-if="!$v.name.minLength">Field must have at least {{ $v.name.$params.minLength.min }} letters.</p>
    <InputStyled :required="!$v.symbol.required">
      <input v-model.trim="$v.symbol.$model" :placeholder="'short name'" />
    </InputStyled>
    <p class="errors" v-if="!$v.symbol.isUnique">{{ $tc('messages.notUnique') }}</p>
    <p class="errors" v-if="$v.symbol.$dirty && !$v.symbol.required">{{ $tc('messages.required') }}</p>
    <ButtonsGroup>
      <ButtonStyled
        :disabled="!$v.name.required || !$v.name.minLength || !$v.name.isUnique || !$v.symbol.required || !$v.symbol.isUnique"
        @click="saveAssetType"
        >{{ $tc('actions.create') }}</ButtonStyled
      >
      <ButtonStyled color="grey" @click="$root.$emit('closeOverlay')">{{ $tc('actions.cancel') }}</ButtonStyled>
    </ButtonsGroup>
  </CreateAssetTypeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'

import { buttonReset, flexCenter } from '@styles/mixins'

import ASSET_TYPES_QUERY from '#/graphql/assetTypes/assetTypes.gql'
import CREATE_ASSET_TYPE_MUTATION from '#/graphql/assetTypes/createAssetType.gql'

const InputStyled = styled('div')`
  width: 100%;
  padding: 1rem 0;
  input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    color: ${props => props.theme.colors.white};
    background: ${props => chroma(props.theme.colors.white).alpha(0.1).css()};
    border-bottom: 1px solid
      ${props => (props.required ? chroma(props.theme.colors.red).alpha(0.8).css() : chroma(props.theme.colors.green).alpha(0.8).css())};
    transition: border-color 0.5s ease;

    &:focus {
      border-color: ${props => props.theme.colors.primary};
    }

    &::placeholder {
      color: ${props => chroma(props.theme.colors.white).alpha(0.5).css()};
    }
  }
`

const ButtonStyled = styled('button')`
  ${props => buttonReset(props)}
  ${flexCenter}
  margin: .5rem;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background: ${props => props.theme.colors.orange};
  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primaryLighter};
  }
  &:disabled {
    color: ${props => chroma(props.theme.colors.muted).brighten(1).css()};
    background: ${props => chroma(props.theme.colors.muted).darken(1).css()};
  }
`

const ButtonsGroup = styled('div')`
  display: flex;
  flex-direction: row;
  float: right;
`

const CreateAssetTypeStyled = styled('div')`
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  padding-top: 50px;

  .errors {
    color: red;
  }
`
export default {
  components: {
    CreateAssetTypeStyled,
    InputStyled,
    ButtonsGroup,
    ButtonStyled,
  },
  mixins: [validationMixin],
  validations: {
    name: {
      required,
      minLength: minLength(3),
      isUnique() {
        const found = this.assetTypes.find(item => item.name.toLowerCase() === this.name.toLowerCase())
        if (found) {
          return false
        }
        return true
      },
    },
    symbol: {
      required,
      isUnique() {
        const found = this.assetTypes.find(item => item.symbol.toLowerCase() === this.symbol.toLowerCase())
        if (found) {
          return false
        }
        return true
      },
    },
  },
  data() {
    return {
      name: '',
      symbol: '',
      assetTypes: [],
    }
  },
  methods: {
    async saveAssetType() {
      await this.$apollo.mutate({
        mutation: CREATE_ASSET_TYPE_MUTATION,
        variables: {
          data: {
            name: this.name,
            symbol: this.symbol,
          },
        },
        update: (store, { data: { createMachineType } }) => {
          let data = store.readQuery({
            query: ASSET_TYPES_QUERY,
          })
          data.assetTypes.push(createMachineType)
          store.writeQuery({
            query: ASSET_TYPES_QUERY,
            data,
          })
          this.$root.$emit('closeOverlay')
        },
      })
    },
  },
  apollo: {
    assetTypes: {
      query: ASSET_TYPES_QUERY,
    },
  },
}
</script>
